<template>
  <div
    class="text-center justify-center justify-content-center text-center my-12"
  >
    <v-row align="center" justify="center">
      <p class="font-weight-bold">لايوجد تنبيهات لعرضها</p>
    </v-row>
    <v-row align="center" justify="center">
      <v-icon color="#11283d" size="120"> mdi-bell-off-outline </v-icon>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>