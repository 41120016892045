import { getItemOrderRequestExcel } from "../apis/index.js";

export default {
  namespaced: true,
  state: {
    grids: [],
  },

  getters: {
    GET_DATAS(state) {
      return state.grids;
    },
  },

  mutations: {
    SET_DATAS(state, datas) {
      state.grids = datas;
    },
  },

  actions: {
    GetItemOrderRequestExcel({ commit }, filter) {
      return new Promise((resolve, reject) => {
        getItemOrderRequestExcel(filter)
          .then((response) => {
            commit("SET_DATAS", response.result);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
